<div class="content">
    <div class="height50"></div>
    <h1>Let's talk</h1>
    <div class="contactgroups">
        <div class="contactgroup contactleft">
            <p class="contactform-cta">Please complete the following form so we can contact you to discuss more.</p>
            <form [(formGroup)]="contactForm" (ngSubmit)="onSubmit()" class="contactform"  >
                <div class="contactformgroup">
                    <div class="line">
                        <div class="numberCircle">1</div>
                        <p>Please tell us a little bit about yourself.</p>
                    </div>
                    <div class="line">
                        <div class="inputField">
                            <label>Full Name </label>
                            <input #full_name type="text" formControlName="full_name" [ngClass]="{'_hasError': isNotValid('full_name') }" />
                            <div class="errorMessage" *ngIf="isNotValid('full_name')">
                                <div>Please indicate your full name.</div>
                            </div>
                        </div>
    
                        <div class="inputField">
                            <label>Email</label>
                            <input #email type="text" formControlName="email" [ngClass]="{'_hasError': isNotValid('email') }" />
                            <div class="errorMessage" *ngIf="isNotValid('email')">
                                <div>We need a valid email address here.</div>
                            </div>
                        </div>

                        <div class="inputField">
                            <label>Company<div class="optionalText">Optional</div></label>
                            <input #company type="text" formControlName="company" />
                        </div>

                    </div>

                </div>
                <div class="contactformgroup">
                    <div class="line">
                        <div class="numberCircle">2</div>
                        <p>Tell us about your idea and how you would like us to help you.</p>
                    </div>
                    <div class="inputField">
                        <textarea [ngClass]="{'_hasError': isNotValid('message')}" formControlName="message"></textarea>
                        <div class="errorMessage" *ngIf="isNotValid('message')">
                            <div>Be sure to let us know why you're reaching out</div>
                        </div>
                    </div>

                </div>

                <div class="contactformgroup">
                    <div class="line">
                        <div class="numberCircle">3</div>
                        <p>Verify you're human and send.</p>
                    </div>

                    <div class="recaptcha" [ngClass]="{'_visible': !buttonVisible}">
                        <re-captcha (resolved)="captchaResolved($event)" siteKey="6LfTtKcZAAAAAIufaRpeIH_tUPK2cBNQ8HsYSkj1" formControlName="token"></re-captcha>
                    </div>
                    
                    <div class="loader-container" *ngIf="this.buttonText == ''">
                        <div class="wave-loader"></div>
                    </div>
                    
                    <button type="submit" class="sendButton" *ngIf="buttonVisible && this.buttonText !== ''">
                        <div>{{buttonText}}</div>
                        
                        <span class="icon" class="material-icons">send</span>
                    </button>
                </div>

                <div class="verticalLine"></div>
            </form>
        </div>

        <div class="contactgroup contactcard contactright">
            <h3>Contact info</h3>
            <!-- <div class="contactgroupitem">
                <div class="icon"><span class="material-icons">phone</span></div>
                <div class="item">+1 (242) 557-4273</div>
            </div> -->
            <div class="contactgroupitem">
                <div class="icon"><span class="material-icons">email</span></div>
                <div class="item">info@aspec.dev</div>
            </div>
            <div class="contactgroupitem">
                <div class="icon"><span class="material-icons">location_on</span></div>
                <div class="item">Nassau, New Providence <br> The Bahamas</div>
            </div>
        </div>
    </div>

</div>
<div class="resultsModal" *ngIf="modalResponse.item == 'success'" [ngClass]="{'_visible': modalResponse.state == 'show', '_hide': modalResponse.state == 'hide'}">
    <div class="modalContent">
        <div class="image move-in">
            <svg id="Capa_1" enable-background="new 0 0 512.005 512.005" height="512" viewBox="0 0 512.005 512.005" width="512" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"/>
            </g>
        </svg>
        </div>
        <div class="message">Contact Request Sent</div>
        <div class="submessage">We've sent you an email confirmation.</div>
    </div>
</div>
<div class="resultsModal" *ngIf="modalResponse.item != 'success'" [ngClass]="{ '_visible': modalResponse.state == 'show', 'hide': modalResponse.state == 'hide' }">
    <div class="modalContent">
        
        <ng-container [ngSwitch]="modalResponse.item">
            <ng-container *ngSwitchCase="'duplicate'">
                <div class="image">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-2.5c2.33 0 4.32-1.45 5.12-3.5h-1.67c-.69 1.19-1.97 2-3.45 2s-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5z"/></svg>
                </div>
                <div class="message">Got it</div>
                <div class="submessage">No need to worry, we've recieved this already.</div>
            </ng-container>
            <ng-container *ngSwitchCase="'maxlimit'">
                <div class="image">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
                </div>
                <div class="message">Woah there!</div>
                <div class="submessage">We're sorry but you've sent too many requests in a short period. Please wait a bit and retry.</div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="image">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
                </div>
                <div class="message">Oops!</div>
                <div class="submessage">This is embarassing. Something went wrong.<br/> Please try again later or send us an email at info@aspec.dev .</div>
            </ng-container>
        </ng-container>
    </div>
</div>