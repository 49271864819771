import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../../config/api.config';
import { ContactRequest } from './contact';

@Injectable({
    providedIn: 'root'
})

export class ContactService {

    constructor(private http: HttpClient) { }

    sendContactRequest(data: ContactRequest) {
        return this.http.post(API.upload, data);
    }

}
