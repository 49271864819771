import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from '../contact/contact.component';
import { AboutComponent } from '../about/about.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ScriptService } from 'src/app/services/scripts/script.service';

const routes: Routes = [
    
    { path: 'contact', component: ContactComponent, pathMatch: 'full' },
    { path: 'about', component: AboutComponent, pathMatch: 'full' },
    { path: '', component: HomeComponent, pathMatch: 'full' },
];



@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule.forChild(routes),
        // FormsModule,
        ReactiveFormsModule,
        // recaptcha
        RecaptchaFormsModule,
        RecaptchaModule
    ],
    declarations: [
        HomeComponent,
        ContactComponent,
        AboutComponent
    ],
    providers: [
        ScriptService
    ]
})
export class HomeModule { }
