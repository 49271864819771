<div id="content" class="content" [ngStyle]="{'height': getContentHeight() }">
    <div class="height100"></div>
    <div class="landing-text">
        <div class="cta" [ngClass]="{'_together': together}">
            <div id="ctaText">Let's build</div>
            <div [ngClass]="{
                    '_transitioning': actionWord.inTransit  == 'move',
                    '_hide': actionWord.inTransit == 'hide' }" id="actionWord">{{actionWord.text}}</div>
        </div>
        <div class="summary">
            We take big ideas and transform them into bespoke digital solutions for your business.
        </div>
        <div class="ctaButtons">
            <button [routerLink]="[ '/contact']" class="ctaButton">
                <div>Let's talk

                </div> <span class="material-icons">
                    arrow_forward
                </span>
            </button>
        </div>
    </div>
    <div class="landing-graphic">
        <img title="A simple illustration depicting a metropolitan cityscape of tall buildings with a construction crane on top of a building-sized smartphone." src="../../../assets/svg/skyline.svg">
    </div>
</div>