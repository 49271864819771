import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {


    links = [
        // {text: 'About', link: ['/about']},
        // {text: 'Services', link: 'services'},
        {text: 'Contact', link: ['/contact']},
    ];

  constructor() { }

  ngOnInit() {}

}
