import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ContactService } from 'src/app/services/contact/contact.service';
import { ScriptService } from 'src/app/services/scripts/script.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    
    contactForm: FormGroup;
    submitAttempt = false;
    
    token: string;
    buttonVisible = false;

    modalResponse = {
        item: 'success', // fail
        state: 'hidden'
    };

    buttonText = 'Contact us';
    

    constructor(
        private fb: FormBuilder,
        private contactSvc: ContactService,
        private scripts: ScriptService) { }
   

    ngOnInit() {

        this.scripts.load('recaptchav2');

        this.contactForm = this.fb.group({
            full_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            company: [''],
            message: ['', Validators.required],
            token: ['', Validators.required]
        });

        
    }

    isNotValid(field: string) {

        try {
            const control = this.contactForm.get(field);

            if (this.submitAttempt) {
                if (!control.valid) {
                    return true;
                }
            } else if (!control.valid && control.dirty) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
       
    }

    captchaResolved($event: any) {

        if ($event == null) {
            this.buttonVisible = false;
        } else {
            this.buttonVisible = true;
            this.token = $event.toString();
        }
    }

    onSubmit() {

        this.buttonText = '';
        this.submitAttempt = true;
        if (this.contactForm.valid) {
            this.contactSvc.sendContactRequest(Object.assign({}, this.contactForm.value))
                .subscribe((result) => {
                    if (result['data']['state'] == 'success') {
                        this.onSuccess();
                    } else {
                        this.onError(result['data']['state']);
                    }
                    this.buttonText = 'Contact us';
                }, (error) => {

                    this.buttonText = 'Contact us'
                    if (error.status == 429) {
                        this.onError('maxlimit');
                    } else {
                        this.onError('error');
                    }
                    ;
                });
        } else {
            alert('Please ensure all fields have been completed.');
            this.buttonText = 'Contact us';
        }
    }

    onSuccess() {
        this.contactForm.markAsPristine();
        this.contactForm.reset();
        this.submitAttempt = false;

        this.modalResponse.item = 'success';
        this.modalResponse.state = 'show';

        setTimeout(() => {
            this.modalResponse.state = 'hide';
            setTimeout(() => {
                this.modalResponse.state = 'hidden';
            }, 1000)
        }, 5000)
    }

    onError(state: string) {

        this.modalResponse.item = state;
        this.modalResponse.state = 'show';
        setTimeout(() => {
            this.modalResponse.state = 'hide';
            setTimeout(() => {
                this.modalResponse.state = 'hidden';
            }, 1000)
        }, 5000)
    }
}
