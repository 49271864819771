<div id="header">
    <div class="header-box">
        <div id="logo-box" [routerLink]="['']">
            <svg routerLinkActive="router-link-active"  version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 960 560"
                style="enable-background:new 0 0 960 560;" xml:space="preserve">
    
                <polygon id="XMLID_414_" class="st0" points="142.9,311.1 240.9,253.8 339.6,310 241.6,367.3 " />
                <g id="XMLID_413_">
                    <path id="XMLID_27_" class="st1" d="M422.4,251.7c8.5,0,15.1,2.6,18.2,4.7v-5.5c0-7.4-5.5-12.4-15.3-12.4c-8.4,0-16.1,2.7-22.4,6
                        l-8.4-15.1c7.6-5.1,20.3-10,33.1-10c27,0,35.1,14.2,35.1,33v53.2h-19.6l-1.4-5.5c-4.3,4.7-10.8,7.6-20.4,7.6
                        c-17,0-31.5-10.5-31.5-28.1C389.8,263,402.8,251.7,422.4,251.7z M426.6,290.5c8.4,0,14.5-4.5,14.5-11.6c0-7.4-6.1-11.7-14.6-11.7
                        c-8.5,0-14.5,4.8-14.5,11.7C412,285.8,418.2,290.5,426.6,290.5z" />
                    <path id="XMLID_30_" class="st1"
                        d="M473.6,292.1l13-11.6c4.2,4.8,9.8,9.2,19.3,9.2c6.3,0,10.3-2.7,10.3-7.6
                        c0-12.9-39.7-7.9-39.7-37.8c0-15.6,13.3-24.9,30.6-24.9c15.9,0,24.8,8.4,28.8,15l-14.6,10.9c-2.6-3.2-7.2-8-15.1-8
                        c-5.1,0-8.7,2.6-8.7,6.8c0,13.3,39.9,6.9,39.9,37c0,16.4-14.2,26.7-32.3,26.7C488.3,307.7,478.1,299.3,473.6,292.1z" />
                    <path id="XMLID_32_" class="st1" d="M549.1,221.5H570v8.8c2.3-2.9,10.6-10.9,24.9-10.9c24.1,0,41.2,18.3,41.2,44.1
                        c0,26.1-15.8,44.2-39.9,44.2c-14.5,0-22.8-8-24.9-10.8v38.8h-22.2V221.5z M592.2,288.1c12.9,0,21.6-10.1,21.6-24.8
                        c0-15-9-24.3-21.6-24.3c-13.5,0-21.6,10.9-21.6,24.3C570.7,279.1,580,288.1,592.2,288.1z" />
                    <path id="XMLID_35_" class="st1"
                        d="M685.7,219.4c26.1,0,40.8,17.4,40.8,44.9c0,1.6,0,3.2-0.2,4.8h-59.2
                        c0.5,11.3,9.5,19.3,21.9,19.3c11.4,0,18-6.8,20.4-10.3l14.6,11.1c-3.2,6-14.5,18.5-35.4,18.5c-27,0-44.2-18.7-44.2-43.9
                        C644.5,238.4,661.7,219.4,685.7,219.4z M704,254.6c-0.5-10.3-7.9-17-18-17c-10.5,0-17.2,7.4-18.2,17H704z" />
                    <path id="XMLID_38_" class="st1" d="M799.4,280.5l6,18.2c-4.8,3.9-14,9-26.2,9c-26.2,0-44.9-18.3-44.9-43.9
                        c0-25.6,19.1-44.4,44.7-44.4c12.4,0,21.1,4.8,26.5,9l-6.3,18.7c-3.7-3.4-10.1-8-19.6-8c-14.3,0-23.2,11.1-23.2,24.6
                        c0,14.3,10,24.4,24,24.4C788.9,288.1,795.7,283.6,799.4,280.5z" />
                </g>
                <g id="XMLID_410_">
                    <path id="XMLID_2_" class="st2" d="M587.8,322.8h5.4c6.6,0,10.7,3.8,10.7,10.1c0,6.5-4.1,10.1-10.7,10.1h-5.4V322.8z M593.4,339.5
                        c3.8,0,6.3-2.2,6.3-6.6c0-4.3-2.5-6.6-6.3-6.6h-1.4v13.2H593.4z" />
                    <path id="XMLID_5_" class="st2"
                        d="M609,322.8h10.4v3.7H613v4.5h5.4v3.6H613v4.8h6.6v3.6H609V322.8z" />
                    <path id="XMLID_7_" class="st2"
                        d="M630.8,343l-7.5-20.3h4.5l5.2,15.5l5.2-15.5h4.5l-7.4,20.3H630.8z" />
                    <path id="XMLID_9_" class="st2"
                        d="M646.9,322.8h10.4v3.7H651v4.5h5.4v3.6H651v4.8h6.6v3.6h-10.7V322.8z" />
                    <path id="XMLID_11_" class="st2" d="M663,322.8h4.1v16.6h6.6v3.7H663V322.8z" />
                    <path id="XMLID_13_" class="st2" d="M676.1,332.9c0-5.9,4.3-10.5,10.5-10.5c6.2,0,10.5,4.6,10.5,10.5c0,6-4.3,10.5-10.5,10.5
                        C680.4,343.4,676.1,338.9,676.1,332.9z M680.4,332.9c0,3.9,2.6,6.7,6.3,6.7c3.6,0,6.3-2.8,6.3-6.7c0-3.8-2.6-6.7-6.3-6.7
                        C683,326.2,680.4,329.1,680.4,332.9z" />
                    <path id="XMLID_16_" class="st2" d="M702.2,322.8h6.5c4,0,6.7,2.5,6.7,6.3c0,3.8-2.8,6.4-6.6,6.4h-2.4v7.6h-4.1V322.8z
                        M708.1,331.9c1.8,0,3-1.2,3-2.9c0-1.7-1.2-2.8-3-2.8h-1.9v5.6H708.1z" />
                    <path id="XMLID_19_" class="st2" d="M731.1,336.6l5.8-13.8h5.3V343h-3.9v-14.5l-5.3,12.8h-3.8l-5.3-12.8V343H720v-20.3h5.3
                        L731.1,336.6z" />
                    <path id="XMLID_21_" class="st2"
                        d="M748.2,322.8h10.4v3.7h-6.3v4.5h5.4v3.6h-5.4v4.8h6.6v3.6h-10.7V322.8z" />
                    <path id="XMLID_23_" class="st2"
                        d="M768.3,329.2V343h-4v-20.3h4.1l8.7,13.9v-13.9h4V343H777L768.3,329.2z" />
                    <path id="XMLID_25_" class="st2" d="M790.5,326.4h-4.7v-3.7h13.5v3.7h-4.7V343h-4.1V326.4z" />
                </g>
                <polygon id="XMLID_409_" class="st3" points="339.2,310.9 240.5,254.6 239.8,141.2 338.8,197.2 " />
                <polyline id="XMLID_408_" class="st3" points="239.8,141.2 142.9,197.5 241.3,254.7 " />
                <line id="XMLID_416_" class="st4" x1="237.5" y1="252.9" x2="142.9" y2="197.5" />
                <polygon id="XMLID_105_" class="st5" points="339.5,197.5 241.3,254.7 241.9,368.6 340.5,310.9 " />
                <line id="XMLID_418_" class="st6" x1="331.5" y1="213" x2="331.5" y2="308" />
                <g id="XMLID_415_">
                </g>
                <g id="XMLID_1_">
                </g>
                <g id="XMLID_40_">
                </g>
                <g id="XMLID_41_">
                </g>
                <g id="XMLID_42_">
                </g>
                <g id="XMLID_43_">
                </g>
                <g id="XMLID_44_">
                </g>
                <g id="XMLID_45_">
                </g>
                <g id="XMLID_46_">
                </g>
                <g id="XMLID_47_">
                </g>
                <g id="XMLID_48_">
                </g>
                <g id="XMLID_49_">
                </g>
                <g id="XMLID_50_">
                </g>
                <g id="XMLID_51_">
                </g>
                <g id="XMLID_52_">
                </g>
                <g id="XMLID_53_">
                </g>
            </svg>
        </div>
        <app-nav></app-nav>
    </div>
</div>

<div class="page">
    <router-outlet></router-outlet>
</div>
<footer>
    <div id="footer-content">
        Aspec Development &copy; 2020-{{getCurrentYear()}}  Nassau, The Bahamas
    </div>
</footer>