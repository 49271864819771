import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    windowHeight = 0;

    actionWord = {
        text: '<br>',
        inTransit: 'move' };

    together = false;

    actionWords = [ 'apps',
        'websites', 'systems', 'solutions',
        'experiences', 'together']

    constructor() { }

    ngOnInit() {
        this.iterateActionWords();
       
    }

    // calculateWindowHeight() {
    //     let height =  window.innerHeight;
    //     if (height != this.windowHeight) {
    //         this.windowHeight = height;
    //     }
    // }

    getContentHeight() {
        if (this.windowHeight > 0) {
            const reducedHeight = this.windowHeight - 82;
            return `${reducedHeight}px`;
        } else  {
            return '100vh';
        }
    }

    ngAfterViewChecked(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        // this.calculateWindowHeight();
        
    }

    

    iterateActionWords() {
        let count = 0;
        
        setInterval(() => {
        
            if (count < this.actionWords.length) {
                
                this.actionWord.inTransit = 'hide';

                setTimeout(() => {
                    
                    this.actionWord.inTransit = 'move';

                    setTimeout(() => {
                        this.actionWord.inTransit = 'show';
                        this.actionWord.text = this.actionWords[count];
                        count++;
                    }, 100);

                }, 100);
            } else {
                this.together = true;
            }
           
        }, 1000);
    }

}
