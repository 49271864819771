<div id="navbar">
    <ul>
        <a *ngFor="let link of links" [routerLink]="link.link" routerLinkActive="router-link-active"  >
            <li >
                <div>{{link.text}}</div>
                <div class="line"></div>
            </li>
        </a>
        <!--  <li>
            <a>Services</a>
            <div class="line"></div>
            <div class="link">
                <div class="link-box"> 
                    <div class="link-card">
                        <div class="icon">
                            <span class="material-icons">apps</span>
                        </div>
                        <div class="card-text">
                            <h4>Application Development</h4>
                            <p>Made-to-order software development for customers who need uncompromised customization.</p>
                        </div>
                    </div>
                    <div class="link-card">
                        <div class="icon">
                            <span class="material-icons">apps</span>
                        </div>
                      
                        <div class="card-text">
                            <h4>Website Development</h4>
                            <p>Website creation that personifies your vision.</p>
                        </div>
                    </div>
                    <div class="link-card">
                        <div class="icon">
                            <span class="material-icons">apps</span>
                        </div>
                        <div class="card-text">
                            <h4>Consultation</h4>
                            <p>The confidence and expertise you make your next project a success.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </li>
        <li>
            <a>Contact</a>
            <div class="line"></div>
        </li>  -->
    </ul>
    
</div>